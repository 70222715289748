<template>
  <div>
    <v-layout>
      <div class="boxapp section">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>{{ getProjectTypeName(project.projectTypeId) }}</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <Steps :page-options="pageOptions" :project="project" />

          <div class="section list-box">
            <!-- section head -->
            <v-layout>
              <div class="head w-100">
                <h5>PASSO 5</h5>
                <h4>Descrição</h4>
              </div>
              <div class="mt-3 pt-3">
                <v-btn flat round color="white" class="btn btn-primary px-5" large @click="newGoal">
                  <i class="mr-2 fal fa-plus"></i>Metas
                </v-btn>
                <NewGoals
                  ref="newGoalsComponent"
                  :project="project"
                  :groupType="groupTypeList"
                  :groupVerification="groupVerificationList"
                  :numberOfFields="numberOfFields"
                  v-model="project.goalList"
                />
              </div>
            </v-layout>
            <GoalsList
              ref="goalsListComponent"
              :items="project.goalList"
              @edit="editGoal"
              @delete="deleteGoal"
            />
          </div>

          <BorderSeparation />

          <div class="section">
            <div class="head">
              <h4>Investimento</h4>
            </div>

            <div class="wrapper">
              <v-layout row wrap>
                <v-flex xs6 pr-2>
                  <InputMoney
                    ref="totalValueInput"
                    v-model="project.totalValue"
                    textLabel="Valor total do projeto"
                    helpLabel="Valor total autorizado pelo órgão competente"
                    invalidInputMessage="Preencha o valor total do projeto"
                    @validationStatusChanged="validateStep"
                    :precision="2"
                  />
                </v-flex>
                <v-flex xs6>
                  <InputMoney
                    ref="capturedValueInput"
                    v-model="project.capturedValue"
                    textLabel="Valor já captado com outros parceiros"
                    helpLabel="Valor captado com outros parceiros"
                    :required="false"
                    :precision="2"
                  />
                </v-flex>
                <v-flex xs6 pr-2>
                  <InputDate
                    ref="captureDateLimitInput"
                    v-model="project.captureDateLimit"
                    textLabel="Data limite para captação do projeto"
                    :min="project.initialDate"
                    :max="project.finalDate"
                    placeholder="__/__/____"
                    invalidInputMessage="Preencha a data limite para captação do projeto"
                    @validationStatusChanged="validateStep"
                  ></InputDate>
                </v-flex>
              </v-layout>
            </div>
          </div>

          <BorderSeparation />

          <template
            v-if="project.projectTypeId == projectTypes.ProjetoSemIncentivoFiscal || project.incentiveLawId == incentiveLaws.LeiFederalDoEsporte || project.incentiveLawId == incentiveLaws.LeiIncentivoCulturaRouanet"
          >
            <div class="section">
              <div class="head">
                <h4>Conta bancária</h4>
              </div>
              <v-layout row wrap w-100>
                <v-flex sm4 xs12 pr-2>
                  <InputSelect
                    ref="bankNumberInput"
                    v-model="project.bankData.bankId"
                    :items="bankList"
                    textLabel="Banco"
                    valueAttribute="id"
                    textAttribute="name"
                    invalidInputMessage="Selecione o banco"
                    @validationStatusChanged="validateStep"
                  ></InputSelect>
                </v-flex>
                <v-flex sm4 xs12 pr-2>
                  <InputText
                    ref="agencyInput"
                    v-model="project.bankData.agency"
                    type="text"
                    place-holder="Nº agência"
                    textLabel="Agência"
                    invalidInputMessage="Preencha a agência" 
                    :validation="validateAgency"
                    :mask="['####', '####-X']"
                  />
                </v-flex>
                <v-flex sm3 xs12 pr-2>
                  <InputText
                    ref="accountInput"
                    v-model="account"
                    type="text"
                    place-holder="Nº da conta"
                    textLabel="Conta Corrente"
                    invalidInputMessage="Preencha a conta corrente"
                    :validation="validateAccount"
                  />
                </v-flex>
                <v-flex sm1 xs12>
                  <InputText
                    ref="digitInput"
                    v-model="digit"
                    type="text"
                    place-holder="Dígito da conta"
                    textLabel="Dígito"
                    invalidInputMessage="Preencha um dígito"
                    :validation="validateDigit"
                    @validationStatusChanged="validateStep"
                  />
                </v-flex>
              </v-layout>
            </div>

            <BorderSeparation />
          </template>

          <!-- Removeido por tempo indeterminado -->
          <sign-receipt
            v-if="false"
            ref="signReceiptComponent"
            :items="project.signReceipts"
            :project-incentive-law="project.incentiveLaw"
          ></sign-receipt>
          <empty-signer-receipt
            v-if="false"
            @eleger="eleger"
            ref="emptySignerReceiptComponent"
            :items="notSignReceipts"
            :project-incentive-law="project.incentiveLaw"
            :entityId="entityId"
          ></empty-signer-receipt>

          <!-- <div class="section">
            <div class="checkbox-father">
              <v-checkbox
                v-model="project.hasConterpart"
                color="#02ACB4"
                class="ma-0"
                label="Este projeto possui agradecimentos para doações diferenciadas"
                @change="validateStep"
              ></v-checkbox>
            </div>
          </div>-->

          <!-- <div v-if="project.hasConterpart" class="section">
            <v-layout row wrap>
              <div class="head appreciation">
                <h4>Agradecimentos</h4>
                <AppreciationEdit
                  size="16"
                  iconButton="ml-3 fal fa-gift"
                  textContent="Cadastrar"
                  colorButton="btn btn-primary px-4 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                ></AppreciationEdit>
              </div>
          </v-layout>-->
          <!-- <v-layout row wrap w-100>
              <v-flex xs12 sm6 pr-2>
                <InputText
                  ref="quotaNameInput"
                  v-model="project.quotaName"
                  type="text"
                  place-holder="Nome"
                  textLabel="Nome da cota"
                  invalidInputMessage="Preencha o noma da cota"
                  @validationStatusChanged="validateStep"
                  labelCharLimit="50"
                />
              </v-flex>
              <v-flex xs12 sm6>
                <InputMoney
                  ref="quotaValueInput"
                  v-model="project.quotaValue"
                  textLabel="Valor da cota"
                  invalidInputMessage="Preencha o valor da cota"
                  @validationStatusChanged="validateStep"
                />
              </v-flex>
              <v-flex>
                <InputTextArea
                  ref="benefitsInput"
                  v-model="project.benefits"
                  labelAreaCharLimit="150"
                  place-holder="Descreva os benefícios"
                  textLabel="Benefícios"
                  invalidInputMessage="Preencha os benefícios"
                  @validationStatusChanged="validateStep"
                ></InputTextArea>
              </v-flex>
          </v-layout>-->
          <!-- </div>
          <BorderSeparation />-->
          <div class="section">
            <v-layout>
              <div class="head w-100">
                <h4>Principais Apoiadores</h4>
              </div>
              <div class="mt-3 pt-3">
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn btn-primary px-4"
                  large
                  @click="addNewSupporter"
                >
                  <i class="mr-2 fas fa-plus"></i>Novo apoiador
                </v-btn>
              </div>
            </v-layout>
            <v-layout
              w-100
              v-for="(supporter, index) in project.supporterList"
              :key="supporter.Order"
            >
              <v-flex xs5 pr-2>
                <InputText
                  ref="supporterNameInput"
                  v-model="supporter.name"
                  type="text"
                  class="mb-0"
                  place-holder="Nome do Apoiador"
                  textLabel="Nome do apoiador"
                  :focusOnCreate="true"
                  invalidInputMessage="Preencha o nome do apoiador"
                  @validationStatusChanged="validateStep"
                />
              </v-flex>
              <InputFile
                ref="supporterImageInput"
                v-model="project.supporterList[index]"
                textLabel="Logo"
                place-holder="Upload [PNG, JPG, PDF]"
                invalidInputMessage="Selecione a imagem do apoiador"
                helpLabel="Imagens sem fundo ou c/ fundo branco. Upload [PNG,JPG,PDF]"
                @validationStatusChanged="validateStep"
              />
              <v-flex xs2 tools pt-4>
                <v-layout row align-center justify-space-between fill-height pb-3>
                  <v-checkbox
                    v-model="supporter.show"
                    class="no-message mt-0 align-center justify-space-between"
                    color="#02ACB4"
                    label="Exibir"
                  ></v-checkbox>
                  <button href class="text-gray-dark" @click="removeSupporter(supporter)">
                    <Icon :name="'fal fa-trash'" :size="16" />
                  </button>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="text" />
    </v-layout>
    <FooterStep
      v-model="pageOptions"
      :validationMethod="isValid"
      nextLabelText="Concluir"
      @clickNext="pageOptions.currentPage++"
      :project="project"
    />
    <AucAlert
      ref="alertComponent"
      title="Você tem certeza que deseja excluir esse apoiador?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="confirmRemoveSupporter"
    />
  </div>
</template>

<script type="plain/text">
import HelpBox from "@/components/HelpBox.vue";
import NewGoals from "@/components/NewGoals.vue";
import Steps from "@/components/Steps.vue";
import BorderSeparation from "@/components/BorderSeparation.vue";
import FooterStep from "@/components/FooterStep.vue";
import GoalsList from "@/components/GoalsList.vue";
import Goal from "@/scripts/models/goal.model";
import BankData from "@/scripts/models/bankData.model";
import Supporter from "@/scripts/models/supporter.model";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service";
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum.js";
import { UserOccupation } from "@/scripts/models/enums/userOccupation.enum.js";
import { IncentiveLaws } from "@/scripts/models/enums/incentiveLaws.enum.js";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { GetCurrentOrder } from "@/scripts/helpers/list.helper";
import AucAlert from "@/components/AucAlert.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import store from "@/store.js";
import AppreciationEdit from "@/components/AppreciationEdit.vue";
import SignReceipt from "../../../components/project/SignReceipt";
import PersonService from "../../../scripts/services/person.service";
import EmptySignerReceipt from "../../../components/project/EmptySignerReceipt";

export default {
  extends: ValidatableComponent,
  components: {
    EmptySignerReceipt,
    SignReceipt,
    HelpBox,
    Steps,
    NewGoals,
    BorderSeparation,
    FooterStep,
    GoalsList,
    AucAlert,
    AppreciationEdit
  },
  props: ["pageOptions"],
  data() {
    return {
      text: HelpBoxText.ProjectStep5,
      groupVerificationList: [],
      groupTypeList: [],
      bankList: [],
      project: new Project(),
      projectService: new ProjectService(),
      personService: new PersonService(),
      supporter: new Supporter(),
      isNewGoal: false,
      saving: false,
      first: null,
      numberOfFields: 0,
      saveQueue: [],
      account: "",
      digit: "",
      banco: [
        {
          id: "1",
          name: "Banco do Brasil",
          number: "001"
        }
      ],
      getProjectTypeName: GetProjectTypeName,
      incentiveLaws: IncentiveLaws,
      projectTypes: ProjectTypes,
      projectValidationStatusIndex: 6, //o step5_0 é o índice 5
      selectedSupporter: -1,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Projetos" },
        { text: "Passo 5" }
      ],
      notSignReceipts: [],
      entityId: 0
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.query.projectId && to.query.projectId != 0) {
      let projectService = new ProjectService();
      projectService.findById(to.query.projectId).then(function(data) {
        let showMsg = true;
        let isValid = true;
        if (data.projectTypeId == ProjectTypes.Ticket) {
          store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Essa página não está disponível para esse tipo de projeto."
          );
          next(false);
        } else {
          if (!data.stepsValidationStatus.step3) {
            isValid = false;
            next({
              name: Routes.app.ProjectStep3,
              query: { projectId: data.id }
            });
          } else if (!data.stepsValidationStatus.step4) {
            isValid = false;
            next({
              name: Routes.app.ProjectStep4,
              query: { projectId: data.id }
            });
          } else if (!data.stepsValidationStatus.step5_0) {
            isValid = false;
            showMsg = false;
            next({
              name: Routes.app.ProjectStep50,
              query: { projectId: data.id }
            });
          }
          if (isValid) {
            if(data.goalList[0] && data.goalList[0].frequency)
              data.goalFrequency = Number(data.goalList[0].frequency);
            to.query.project = data;
            var dateFrom = new Date(data.initialFinalDates[0]);
            var dateTo = new Date(data.initialFinalDates[1]);

            var months =
              (dateTo.getFullYear() - dateFrom.getFullYear()) * 12 +
              (dateTo.getMonth() - dateFrom.getMonth());
            to.query.numberOfFields = Math.ceil(
              months == 0 ? 1 : months / Number(data.goalFrequency)
            );
            next();
          } else {
            if (showMsg)
              store.commit(
                "SET_SNACKBAR_MESSAGE",
                "Não é possível entrar nessa página antes de preencher corretamente as anteriores."
              );
          }
        }
      });
    } else {
      next({ name: Routes.app.ProjectStep0 });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.account && this.digit) {
      this.project.bankData.account = this.account + this.digit;
    }
    if (!this.$route.query.saved) {
      this.$route.query.saved = true;
      this.projectService.save(this.project).then(next);
    } else {
      next();
    }
  },
  created() {
    this.project = this.$route.query.project;
    this.entityId = this.$route.query.entityId;

    if (!this.project.bankData) {
      this.project.bankData = new BankData();
    } else {
      let oldStr = this.project.bankData.account;
      this.digit = oldStr.slice(-1);
      this.account = oldStr.slice(0, oldStr.length - 1);
    }
    this.numberOfFields = this.$route.query.numberOfFields;
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.validationMethod = this.validate;
    this.onValidationSuccessMethod = this.setValidStep;
    this.onValidationFailMethod = this.setInvalidStep;
    this.projectService.listAllGoalVerification(
      this.listAllGoalVerificationCallback
    );
    this.projectService.listAllGoalType(this.listAllGoalTypeCallback);
    this.projectService.listAllBank(this.listAllBankCallback);
    this.pageOptions.changeClass("footer");
    this.pageOptions.currentPage = 5;

    //this.findSigner();
  },
  watch: {
    "project.goalList": {
      handler: function(after, before) {
        this.validate();
      },
      deep: true
    }
  },
  methods: {
    hasSigners() {
      return this.project.signReceipts.length;
    },
    findSigner() {
      this.personService
        .listPersonSignReceiptByEntity(this.$route.query.entityId)
        .then(this.callbackSigners);

      this.personService
        .listPersonWithoutSignReceiptByEntity(this.$route.query.entityId)
        .then(this.callbackWithoutSigners);
    },
    callbackSigners(data) {
      if (data.length) {
        this.project.signReceipts = data;
        this.$refs.signReceiptComponent.items = this.project.signReceipts;
      } else {
        this.personService
          .listPersonWithoutSignReceiptByEntity(this.$route.query.entityId)
          .then(this.callbackWithoutSigners);
      }
    },
    callbackWithoutSigners(data) {
      this.notSignReceipts = data;
      if (!this.hasSigners()) {
        this.$refs.emptySignerReceiptComponent.items = this.notSignReceipts;
      }
    },
    eleger() {
      this.findSigner();
    },
    listAllGoalVerificationCallback(data) {
      this.groupVerificationList = data;
    },
    listAllGoalTypeCallback(data) {
      this.groupTypeList = data;
    },
    listAllBankCallback(data) {
      // Somente Banco do Brasil e Caixa para projetos de esporte
      if (this.project.incentiveLawId == IncentiveLaws.LeiFederalDoEsporte) {
        this.bankList = data.filter(
          bank => bank.code == "001" || bank.code == "104"
        );
      } else {
        this.bankList = data;
      }
    },
    // Supporter
    addNewSupporter() {
      let newSupporter = new Supporter();
      newSupporter.Order = GetCurrentOrder(this.project.supporterList);
      if (
        this.project.supporterList.length == 0 ||
        (this.project.supporterList[this.project.supporterList.length - 1]
          .name != "" &&
          this.project.supporterList[this.project.supporterList.length - 1].file
            .path != "")
      ) {
        this.project.supporterList.push(newSupporter);
      } else {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Preencha os todos os campos dos apoiadores antes de adicionar outro"
        );
      }
      this.validateStep();
    },
    removeSupporter(supporter) {
      this.selectedSupporter = supporter;
      this.$refs.alertComponent.open();
    },
    confirmRemoveSupporter() {
      if (this.selectedSupporter.id == 0) {
        var index = this.project.supporterList.findIndex(
          x => x.Order == this.selectedSupporter.Order
        );
        this.project.supporterList.splice(index, 1);
      } else {
        var index = this.project.supporterList.findIndex(
          x => x.id == this.selectedSupporter.id
        );
        this.project.supporterList.splice(index, 1);
        if (this.selectedSupporter.id > 0)
          this.projectService.deleteSupporter(null, this.selectedSupporter.id);
      }
    },
    saveSupporter() {
      if (!this.saving && this.saveQueue.length == 0) {
        this.saving = true;

        this.project.supporterList.forEach(item => {
          item.projectId = this.project.id;
        });

        this.projectService
          .saveSupporterList(this.project.supporterList)
          .then(data => this.saveSupporterListCallback(data))
          .catch(this.saveSupporterError);
      } else {
        this.saveQueue.push(this.project.supporterList);
      }
    },
    saveSupporterListCallback(data) {
      this.project.supporterList = data;

      if (this.saveQueue.length != 0) {
        let lst = this.saveQueue.shift();
        while (this.saveQueue.length > 0) lst = this.saveQueue.shift();

        this.projectService
          .saveSupporterList(lst)
          .then(data => this.saveSupporterListCallback(data))
          .catch(this.saveSupporterError);
      } else {
        this.saving = false;
      }
    },
    saveSupporterError(error) {
      console.log(error);
      this.saveQueue = [];
      this.saving = false;
    },
    // Goal
    editGoal(item) {
      this.isNewGoal = false;
      this.$refs.newGoalsComponent.open(item);
    },
    newGoal() {
      this.isNewGoal = true;
      let goal = new Goal(this.numberOfFields);
      goal.frequency = this.project.goalFrequency;
      this.$refs.newGoalsComponent.open(goal);
    },
    deleteGoal(item) {
      this.$refs.newGoalsComponent.remove(item.id);
    },
    splitValue(value, parts) {
      var result = [];
      let remain = value;
      let partsLeft = parts;
      for (var i = 0; partsLeft > 0; i++) {
        let current = Math.floor((remain + partsLeft - 1) / partsLeft);
        result[i] = current;
        remain -= current;
        partsLeft--;
      }
      return result;
    },
    diffDates() {
      var dateFrom = new Date(this.$route.query.initialDate);
      var dateTo = new Date(this.$route.query.finalDate);

      var months =
        (dateTo.getFullYear() - dateFrom.getFullYear()) * 12 +
        (dateTo.getMonth() - dateFrom.getMonth());
      return months == 0 ? 1 : months;
    },
    getCurrentFrequency() {
      if (this.project.goalList && this.project.goalList[0].frequency) {
        this.project.goalFrequency = Number(this.project.goalList[0].frequency);
        return this.project.goalFrequency;
      } else {
        return 0;
      }
    },
    validateIncreasingSequence(current) {
      var numbers = "0123456789";
      var numbersRev = "9876543210";
      return (
        numbers.indexOf(String(current)) === -1 &&
        numbersRev.indexOf(String(current)) === -1
      );
    },
    validateRepeatSequence(current) {
      var regex = /^(.)\1+$/;
      return !regex.test(String(current).toLowerCase());
    },
    validateAgency() {
      if (this.project.bankData.agency) {
        return (
          this.validateIncreasingSequence(this.project.bankData.agency) &&
          this.validateRepeatSequence(this.project.bankData.agency)
        );
      }
    },
    validateAccount() {
      if (this.account) {
        return (
          this.validateIncreasingSequence(this.account) &&
          this.validateRepeatSequence(this.account)
        );
      }
    },
    validateDigit() {
      var regex = /^[\w]{1}$/;
      return regex.test(String(this.digit).toLowerCase());
    },
    validate(performMethods) {
      if (this.project.goalList == null || this.project.goalList.length < 1) {
        if (performMethods) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Cadastre ao menos uma meta"
          );
        }
      }
      if (
        this.project.captureDateLimit &&
        (this.project.captureDateLimit < this.project.initialDate ||
        this.project.captureDateLimit > this.project.finalDate)
      ) {
        if (performMethods) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Data de captação fora do prazo do Projeto"
          );
        }
      }
      let validate = this.validateComponents(performMethods);
      return validate;
    },
    setValidStep() {
      this.$set(this.project.stepsValidationStatus, "step5", true);
    },
    setInvalidStep() {
      this.$set(this.project.stepsValidationStatus, "step5", false);
    },
    validateStep() {
      let valid = this.isValid(false);
      if (valid) this.setValidStep();
      else this.setInvalidStep();
    }
  }
};
</script>